import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Layout } from 'src/components/organisms/layouts'

export default function MyList() {
  return (
    <Layout>
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            To be done!
          </Typography>
        </Box>
      </Container>
    </Layout>
  )
}
